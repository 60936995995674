import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-988d2b18"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "city-selector"
};
const _hoisted_2 = {
  class: "index-bar"
};
const _hoisted_3 = ["id"];
const _hoisted_4 = {
  class: "index-anchor"
};
const _hoisted_5 = {
  class: "city-group"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "index-list"
};
const _hoisted_8 = ["onClick"];
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useLocationStore } from '@/stores/location';
import { cities } from '@/data/cities';
import TopNav from '@/components/TopNav';
import { ElInput } from 'element-plus';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const locationStore = useLocationStore();
    const searchQuery = ref('');
    const filteredCities = computed(() => {
      if (!searchQuery.value) {
        return cities;
      }
      return cities.map(group => ({
        name: group.name,
        data: group.data.filter(city => city.name.includes(searchQuery.value))
      })).filter(group => group.data.length > 0);
    });
    const selectCity = city => {
      console.log('Selected city:', city);
      // 更新城市信息
      locationStore.setSelectedCity(city);
      router.push('/restaurants');
    };
    const scrollTo = letter => {
      const element = document.getElementById(letter);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(TopNav), {
        title: "选择城市"
      }), _createVNode(_unref(ElInput), {
        modelValue: searchQuery.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchQuery.value = $event),
        placeholder: "搜索城市",
        class: "search-input",
        clearable: ""
      }, null, 8, ["modelValue"]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCities.value, group => {
        return _openBlock(), _createElementBlock("div", {
          key: group.name,
          class: "index-group",
          id: group.name
        }, [_createElementVNode("div", _hoisted_4, _toDisplayString(group.name), 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.data, city => {
          return _openBlock(), _createElementBlock("div", {
            key: city.adcode,
            class: "city-item",
            onClick: $event => selectCity(city)
          }, _toDisplayString(city.name), 9, _hoisted_6);
        }), 128))])], 8, _hoisted_3);
      }), 128))]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCities.value, group => {
        return _openBlock(), _createElementBlock("div", {
          key: group.name,
          class: "index-letter",
          onClick: $event => scrollTo(group.name)
        }, _toDisplayString(group.name), 9, _hoisted_8);
      }), 128))])]);
    };
  }
};