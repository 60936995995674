import { defineStore } from 'pinia'
import { queryNearList, queryGaodeGeo } from '@/api/store'
import jsonp from 'jsonp'

export const useStoreStore = defineStore('store', {
  state: () => ({
    stores: [],
    gaodeGeo: {},
    poiResults: []
  }),
  actions: {
    async fetchStores(city, store_name, lng, lat) {
      try {
        // 如果 city 包含 "重庆"，将其替换为 "重庆城区"
        if (city.includes('重庆')) {
          city = '重庆城区';
        }
        const response = await queryNearList({ city, store_name: store_name, lng, lat })
        this.stores = response.data.body
      } catch (error) {
        console.error('Error fetching stores:', error)
      }
    },
    async fetchGaodeGeo(lng, lat) {
      const response = await queryGaodeGeo({ lng, lat })
      this.gaodeGeo = response.data
    },
    searchPOI(city, keyword) {
      return new Promise((resolve, reject) => {
        const timestamp = new Date().getTime()
        const params = {
          boundary: `region(${city},1)`,
          page_size: 20,
          page_index: 1,
          keyword: keyword,
          key: '7XBBZ-LWG6W-BV6RL-RYKXJ-DNRXQ-6PFAC',
          apptag: 'h5pickpoint_place_search',
          output: 'jsonp',
          _: timestamp,
          callback: 'cb' // JSONP 回调函数名
        }
        const queryString = Object.keys(params)
          .map(key => `${key}=${encodeURIComponent(params[key])}`)
          .join('&')
        const url = `https://h5gw.map.qq.com/ws/place/v1/search?${queryString}`
    
        jsonp(url, { name: 'cb' }, (err, data) => {
          if (err) {
            console.error('Error fetching POI:', err)
            reject(err)
          } else {
            if (data.status === 0) {
              this.poiResults = data.data
              resolve(data.data)
            } else {
              console.error('Error fetching POI:', data.message)
              reject(new Error(data.message))
            }
          }
        })
      })
    }
    
  }
})